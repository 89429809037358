#header .search-box {
  background-color: transparent;
  border: 1px solid #CCC;
  border-radius: 3px;
  height: auto;
  text-align: left;
}

// for small width nav search
#header .shortcuts .search-box {
  border: none;
}

.search-box input {
  border: 0 solid;
  outline: none;
  width: 85%;
  height: 110%;
  box-shadow: none !important;
}

.search-box input:focus {
  box-shadow: none !important;
}
